import React from "react"
import { Link } from "gatsby"
//import Icon from "../Utilities/Icon"
import formatNumComma from "../../js/formatNumComma"
import statWinner from "../../js/statWinner"
import styles from "./ModalDetailedStats.module.css"

import ModalStat from "./ModalStat"


class ModalDetailedStats extends React.Component {

  render() {

    // If 'Shots' data is available, show the Performance Stats section
    let performanceActive;
    const dribbles = this.props.rdata.map(d => d.rsuccessfulDribbles)[0] || this.props.mdata.map(d => d.msuccessfulDribbles)[0]
    dribbles && dribbles !== "-" && (this.props.showPerfStats !== 'no') ? performanceActive = true : performanceActive = false

    performanceActive = true;

    let [{...md}] = this.props.mdata.length > 0 ? this.props.mdata : [{mid: "000", mcompetition: "none"}]
    let [{...rd}] = this.props.rdata.length > 0 ? this.props.rdata : [{rid: "000", rcompetition: "none"}]


    //for(var i in d) { d[i] = Number(d[i]) }

    //console.log(d)

    const msg = (season, comps = "League + UCL") => {
      //return this.props.perfmsg ? this.props.perfmsg.replace('[comps]', comps).replace('[season]', season) : ''
      return "Data not available for all games"
    }

    return (
        
      <>
        <h3>Goal Contributions</h3>

        <ul className={`${styles.statsBlock}`}>
          <ModalStat
            statType="Goals"
            mstat={formatNumComma(md.mgoals)}
            rstat={formatNumComma(rd.rgoals)}
            statWinner={statWinner(md.mgoals, rd.rgoals)}
          />
          <ModalStat
            statType="Assists"
            mstat={formatNumComma(md.massists)}
            rstat={formatNumComma(rd.rassists)}
            statWinner={statWinner(md.massists, rd.rassists)}
          />
          <ModalStat
            statType="Appearances"
            mstat={formatNumComma(md.mapps)}
            rstat={formatNumComma(rd.rapps)}
          />
          <ModalStat
            statType="Minutes Played"
            mstat={formatNumComma(md.mminsPlayed)}
            rstat={formatNumComma(rd.rminsPlayed)}
          />
          <ModalStat
            statType="Goals Per Game"
            mstat={md.mgoalsPerGame ? parseFloat(md.mgoalsPerGame).toFixed(2) : '0.00'}
            rstat={rd.rgoalsPerGame ? parseFloat(rd.rgoalsPerGame).toFixed(2) : '0.00'}
            statWinner={statWinner(md.mgoalsPerGame, rd.rgoalsPerGame)}
          />
          <ModalStat
            statType="Minutes Per Goal"
            mstat={md.mminsPerGoal ? parseFloat(md.mminsPerGoal).toFixed(1) : '0.0'}
            rstat={rd.rminsPerGoal ? parseFloat(rd.rminsPerGoal).toFixed(1) : '0.0'}
            statWinner={statWinner(md.mminsPerGoal, rd.rminsPerGoal, 'lowWins')}
          />
          <ModalStat
            statType="Mins Per Goal/Assist"
            mstat={md.mminsPerGoalContribution ? parseFloat(md.mminsPerGoalContribution).toFixed(1) : '0.0'}
            rstat={rd.rminsPerGoalContribution ? parseFloat(rd.rminsPerGoalContribution).toFixed(1) : '0.0'}
            statWinner={statWinner(md.mminsPerGoalContribution, rd.rminsPerGoalContribution, 'lowWins')}
          />
          <ModalStat
            statType="Hat-tricks"
            mstat={md.mhatTricks}
            rstat={rd.rhatTricks}
            statWinner={statWinner(md.mhatTricks, rd.rhatTricks)}
          />
        </ul>


        <h3>Goals By Type</h3>

        <ul className={`${styles.statsBlock}`}>
          <ModalStat
            statType="Penalties"
            mstat={md.mpens}
            msubStat={md.mpensMissed}
            rstat={rd.rpens}
            rsubStat={rd.rpensMissed}
            statWinner={statWinner(md.mpens, rd.rpens)}
            msg="not including shootouts"
          />

          <ModalStat
            statType="Free Kick Goals"
            mstat={md.mfreeKicks}
            rstat={rd.rfreeKicks}
            statWinner={statWinner(md.mfreeKicks, rd.rfreeKicks)}
          />

          <ModalStat
            statType="Outside the Box"
            mstat={md.moutsideBox}
            rstat={rd.routsideBox}
            statWinner={statWinner(md.moutsideBox, rd.routsideBox)}
            msg="not including free kick goals"
          />

          <ModalStat
            statType="Inside the Box"
            mstat={md.minsideBox}
            rstat={rd.rinsideBox}
            statWinner={statWinner(md.minsideBox, rd.rinsideBox)}
            msg="not including penalty goals"
          />

          <ModalStat
            statType="Left Foot"
            mstat={md.mleft}
            rstat={rd.rleft}
            statWinner={statWinner(md.mleft, rd.rleft)}
          />

          <ModalStat
            statType="Right Foot"
            mstat={md.mright}
            rstat={rd.rright}
            statWinner={statWinner(md.mright, rd.rright)}
          />

          <ModalStat
            statType="Headers"
            mstat={md.mhead}
            rstat={rd.rhead}
            statWinner={statWinner(md.mhead, rd.rhead)}
          />

          <ModalStat
            statType="Other Body Part"
            mstat={md.mother}
            rstat={rd.rother}
            statWinner={statWinner(md.mother, rd.rother)}
          />
        </ul>


        {performanceActive ? (

          <>

            <h3>Performance Stats</h3>

            {/* <p className={`text-xs text-gray-700 mb-3 lg:mb-0 px-2`}>These stats are <strong className="font-semibold">League + Champions League since 2009/10</strong> only (unless otherwise stated)</p> */}

            <ul className={`${styles.statsBlock}`}>
              <ModalStat
                statType="Shots"
                mstat={formatNumComma(md.mshots)}
                rstat={formatNumComma(rd.rshots)}
                statWinner={statWinner(md.mshots, rd.rshots)}
                msg={msg('2009/10')}
              />

              <ModalStat
                statType="Shots on Target"
                mstat={formatNumComma(md.mshotsOnTarget)}
                rstat={formatNumComma(rd.rshotsOnTarget)}
                statWinner={statWinner(md.mshotsOnTarget, rd.rshotsOnTarget)}
                msg={msg('2009/10')}
              />

              <ModalStat
                statType="% of Shots on Target"
                mstat={`${parseFloat(Number(md.mshotsOnTarget) / Number(md.mshots) * 100).toFixed(1)}%`}
                rstat={`${parseFloat(Number(rd.rshotsOnTarget) / Number(rd.rshots) * 100).toFixed(1)}%`}
                statWinner={statWinner(Number(md.mshotsOnTarget) / Number(md.mshots), Number(rd.rshotsOnTarget) / Number(rd.rshots))}
                msg={msg('2009/10')}
              />

              <ModalStat
                statType="Shots Per Goal"
                mstat={parseFloat(md.mshotsPerGoal).toFixed(2)}
                rstat={parseFloat(rd.rshotsPerGoal).toFixed(2)}
                statWinner={statWinner(md.mshotsPerGoal, rd.rshotsPerGoal, 'lowWins')}
                msg={msg('2009/10')}
              />

              <ModalStat
                statType="Successful Dribbles"
                mstat={formatNumComma(md.msuccessfulDribbles)}
                rstat={formatNumComma(rd.rsuccessfulDribbles)}
                statWinner={statWinner(md.msuccessfulDribbles, rd.rsuccessfulDribbles)}
                msg={msg('2003/04')}
              />

              <ModalStat
                statType="Key Passes"
                mstat={formatNumComma(md.mkeyPasses)}
                rstat={formatNumComma(rd.rkeyPasses)}
                statWinner={statWinner(md.mkeyPasses, rd.rkeyPasses)}
                msg={this.props.competition  === 'All Time League' || this.props.competition  === 'All Time La Liga' || this.props.competition === "La Liga - Barcelona" || this.props.competition === "Premier League - Manchester United" || this.props.competition === "All Time Premier League" ? msg('2006/07') : msg('2009/10')}
              />

              <ModalStat
                statType="Big Chances Created"
                mstat={formatNumComma(md.mbigChancesCreated)}
                rstat={formatNumComma(rd.rbigChancesCreated)}
                statWinner={statWinner(md.mbigChancesCreated, rd.rbigChancesCreated)}
                msg={msg('2010/11')}
              />

              <ModalStat
                statType="Aerial Duels"
                mstat={formatNumComma(md.maerialDuels)}
                rstat={formatNumComma(rd.raerialDuels)}
                statWinner={statWinner(md.maerialDuels, rd.raerialDuels)}
                msg={msg('2009/10')}
              />

              <ModalStat
                statType="Expected Goals (xG)"
                mstat={parseFloat(md.mxg).toFixed(2)}
                msubStat={md.mxgc}
                rstat={parseFloat(rd.rxg).toFixed(2)}
                rsubStat={rd.rxgc}
                statWinner={statWinner(md.mxgc, rd.rxgc, 'lowWins')}
                msg={msg('2014/15', 'League')}
              />

              <ModalStat
                statType="Expected Assists (xA)"
                mstat={parseFloat(md.mxa).toFixed(2)}
                msubStat={md.mxac}
                rstat={parseFloat(rd.rxa).toFixed(2)}
                rsubStat={rd.rxac}
                statWinner={statWinner(md.mxac, rd.rxac)}
                msg={msg('2014/15', 'League')}
              />

              <ModalStat
                statType="Penalty Conversion"
                mstat={`${parseFloat(Number(md.mpens) / (Number(md.mpensMissed) + Number(md.mpens)) * 100).toFixed(1)}%`}
                rstat={`${parseFloat(Number(rd.rpens) / (Number(rd.rpensMissed) + Number(rd.rpens)) * 100).toFixed(1)}%`}
                statWinner={statWinner(Number(md.mpens) / (Number(md.mpensMissed) + Number(md.mpens)), Number(rd.rpens) / (Number(rd.rpensMissed) + Number(rd.rpens)))}
                msg="not including shootouts"
              />

              <ModalStat
                statType="Free Kick Attemtps"
                mstat={formatNumComma(md.mfreeKickAttempts)}
                rstat={formatNumComma(rd.rfreeKickAttempts)}
                msg={msg('2003/04', 'League')}
              />

              <ModalStat
                statType="Free Kick Conversion"
                mstat={`${parseFloat(md.mfkc).toFixed(1)}%`}
                rstat={`${parseFloat(rd.rfkc).toFixed(1)}%`}
                statWinner={statWinner(md.mfkc, rd.rfkc)}
                msg={msg('2003/04', 'League')}
              />

              <ModalStat
                statType="Man of the Match*"
                mstat={formatNumComma(md.mmotm)}
                rstat={formatNumComma(rd.rmotm)}
                statWinner={statWinner(md.mmotm, rd.rmotm)}
                msg={msg('2009/10')}
              />

              <ModalStat
                statType="Average Match Rating*"
                mstat={parseFloat(md.mavgRating).toFixed(2)}
                rstat={parseFloat(rd.ravgRating).toFixed(2)}
                statWinner={statWinner(md.mavgRating, rd.ravgRating)}
                msg={msg('2009/10')}
              />
            </ul>

            <p className="mt-12 text-gray-700 text text-center w-full">To see the exact number of games each performance stat is available for, use the filters in the <Link to={`/match-histories/`} className="underline">Match Histories</Link>, and click "Performance Stat Totals"</p>

            <p className="mt-4 text-gray-500 text-xs text-center w-full">* Match ratings and MOTM based on WhoScored, SofaScore or FotMob ratings depending on availability</p>
          </>
        ) : '' }
      </>
        
    )
  }
}

export default ModalDetailedStats